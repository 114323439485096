import { useFeature } from "@optimizely/react-sdk";

export interface IBrilliantDeviceCard {
  id: string;
  name: string;
  description: string;
  isReconfiguring?: boolean;
  icon: React.ReactNode;
  isOffline?: boolean;
  lastOnline?: string;
}

const BrilliantDeviceCard: React.FC<IBrilliantDeviceCard> = ({
  id,
  name,
  description,
  isReconfiguring,
  icon,
  isOffline,
  lastOnline,
}) => {
  const [brilliantDev] = useFeature("offline_brilliant_control");
  return (
    <div className="brilliant-card-container">
      {icon}
      <div className="brilliant-card-description">
        <div className={`${isReconfiguring || isOffline ? "disabled" : ""}`}>
          <div className="unit-title hidden-name">{name}</div>
          <div className="brilliant-card-description-type unit-description-small flex-display uppercase">
            {description}
          </div>
        </div>
        {isReconfiguring && (
          <div
            className="brilliant-card-description-reconfiguring unit-device-id"
            data-testid={`device-card-reconfiguration-${id}`}
          >
            RECONFIGURING...
          </div>
        )}
        {isOffline && (
          <div className="brilliant-card-installation-error">OFFLINE</div>
        )}
        {lastOnline && brilliantDev && (
          <div className="brilliant-card-installation-error">
            Seen at {lastOnline}
          </div>
        )}
      </div>
    </div>
  );
};

export default BrilliantDeviceCard;

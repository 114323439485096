import { RenderEmptyHomeView, quantityDisplay } from "../../../utils/utils";
import { ReactComponent as BrilliantDevicesIcon } from "../../../img/Devices/brilliantDevices.svg";
import { ReactComponent as NoIntegrationDevices } from "../../../img/Devices/NoIntegrationDevices.svg";
import {
  IBrilliantControlsCard,
  IBrilliantPlugCard,
  IBrilliantSwitchCard,
  IIntegrationsCard,
} from "../../../interfaces/interfaces";
import BrilliantSwitchCard from "../BrilliantSwitchCard";
import BrilliantPlugCard from "../BrilliantPlugCard";
import BrilliantControlCard from "../BrilliantControlCard";
import IntegrationCard from "../IntegrationCard";

interface IBrilliantDevicesProps {
  brilliantControls: IBrilliantControlsCard[];
  brilliantSwitches: IBrilliantSwitchCard[];
  brilliantPlugs: IBrilliantPlugCard[];
  isInReconfigState: boolean;
}

export const BrilliantDevices: React.FC<IBrilliantDevicesProps> = ({
  brilliantControls,
  brilliantSwitches,
  brilliantPlugs,
  isInReconfigState,
}) => {
  const numBrilliants =
    brilliantControls.length + brilliantSwitches.length + brilliantPlugs.length;
  if (numBrilliants === 0) {
    return (
      <div className="brilliant-container-small-padding">
        <RenderEmptyHomeView
          icon={BrilliantDevicesIcon}
          iconClassName="unit-empty-icon"
          iconTitle="BrilliantDevices"
          componentTitle={
            isInReconfigState
              ? "This unit's Brilliant devices are being reconfigured"
              : "No Brilliant devices were found"
          }
          componentDescription={
            isInReconfigState
              ? "They will appear here soon."
              : "Log in to the Brilliant mobile app as a Building Manager to install devices."
          }
        />
      </div>
    );
  }

  return (
    <div data-testid="brilliant-devices" className="brilliant-container-cards">
      {brilliantControls.map((element: IBrilliantControlsCard) => {
        return <BrilliantControlCard key={element.id} data={element} />;
      })}
      {brilliantSwitches.map((element: IBrilliantSwitchCard) => {
        return <BrilliantSwitchCard key={element.id} data={element} />;
      })}
      {brilliantPlugs.map((element: IBrilliantPlugCard) => {
        return <BrilliantPlugCard key={element.id} data={element} />;
      })}
    </div>
  );
};

interface IIntegrationDevicesProps {
  integrationDevices: IIntegrationsCard[];
  isInReconfigState: boolean;
}

export const IntegrationDevices: React.FC<IIntegrationDevicesProps> = ({
  integrationDevices,
  isInReconfigState,
}) => {
  if (integrationDevices.length === 0) {
    return (
      <div className="brilliant-container-small-padding">
        <RenderEmptyHomeView
          icon={NoIntegrationDevices}
          iconClassName="unit-empty-icon-integrations"
          iconTitle="Integration Devices"
          componentTitle={
            isInReconfigState
              ? "This unit's integrations are being reconfigured"
              : "No integrations were found"
          }
          componentDescription={
            isInReconfigState ? "They will appear here soon." : ""
          }
        />
      </div>
    );
  }

  return (
    <div className="brilliant-container-cards">
      {integrationDevices.map((element: IIntegrationsCard) => {
        return (
          <IntegrationCard
            key={element.name}
            data={element}
            isReconfiguring={isInReconfigState}
          />
        );
      })}
    </div>
  );
};

export const renderUnitDetailsElementAmount = ({
  numDevices,
  nameOfSingleDevice,
}: {
  numDevices: number;
  nameOfSingleDevice: string;
}): JSX.Element => {
  return (
    <>
      <span className={`unit-details-number menu ${!numDevices && "alert"}`}>
        {numDevices}
      </span>
      {quantityDisplay(numDevices, nameOfSingleDevice)}
    </>
  );
};

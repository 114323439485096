import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { useFeature } from "@optimizely/react-sdk";
import _ from "lodash";

import {
  DeviceStatus,
  DeviceTypes,
  EUnitDetailsFilter,
  IAlert,
  IAppState,
  IBrilliantControlsCard,
  IBrilliantDevices,
  IBrilliantPlugCard,
  IBrilliantSwitchCard,
  IInstalledIntegrationDevice,
  IOrganizationData,
  IOrganizationParams,
  IPropertyIdVariable,
  IUnitDetailsData,
  IUnitDetailsIntegrations,
} from "../../interfaces/interfaces";
import {
  smallHeaderBackArrow,
  smallHeaderDescription,
  smallHeaderTitle,
} from "../apollo/LocalState";
import {
  checkIntegrations,
  renderIntegrationInfo,
} from "../../utils/integrationUtils";
import { isReconfigurationInProgress } from "../../utils/reconfigurationUtils";
import Spinner from "../Spinner";
import MakeChangesModal from "../makeChanges/MakeChangesModal";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import history from "../../history";
import { AlertTypes, renderAlertTitle } from "../../utils/alertUtils";
import UnitDetailsView from "../configurations/UnitDetailsView";
import {
  GET_HOME,
  GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
} from "../../api/gqlQueries";
import InviteTenantModalWizard from "../tenantManagement/inviteTenant/InviteTenantModalWizard";
import EditTenantModalWizard from "../tenantManagement/removeTenant/EditTenantModalWizard";
import {
  NEW_INVITE_RESIDENT_STEP,
  SHOW_TENANT_STEP,
} from "../tenantManagement/TenantManagementSteps";
import MoveOutModalWizard from "../tenantManagement/moveOut/MoveOutModalWizard";
import CancelMoveInModal from "../tenantManagement/moveOut/CancelMoveInModal";

import ReconfigurationModal from "./ReconfigurationModal";
import BackupWifiNetworkModal from "./BackupWifiNetworkModal";
import AlertBanner from "./AlertBanner";
import ReconfigurationBanner from "./ReconfigurationBanner";
import UnitDetailsMainInfoForLargeScreen from "./mainContainer/UnitDetailsMainInfoForLargeScreen";
import UnitDetailsMainInfoForSmallScreen from "./mainContainer/UnitDetailsMainInfoForSmallScreen";
import LargeBody from "./body/LargeBody";
import SmallBody from "./body/SmallBody";
import ResetInstallationModalWizard from "./ResetInstallationModalWizard";

export interface UnitDetailsProps {
  match: { params: { propertyId: string } };
  showViewConfiguration: boolean;
  organizationId: string;
  enableMakeChangesFlow?: boolean;
  enableReconfigurationBanner?: boolean;
}

// Used during reconfiguration to compute accurate list of devices to be shown
export function createDeviceDict(data: IUnitDetailsData): IBrilliantDevices {
  const controlDict: Record<string, IBrilliantControlsCard> = {};
  const switchDict: Record<string, IBrilliantSwitchCard> = {};
  const plugDict: Record<string, IBrilliantPlugCard> = {};

  data.home.brilliantControls.forEach((device: IBrilliantControlsCard) => {
    controlDict[device.id] = {
      ...device,
      isReconfiguring: false,
    };
  });
  data.home.installationDetails.installedBrilliantControls.forEach(
    (device: IBrilliantControlsCard) => {
      if (!(device.id in controlDict)) {
        controlDict[device.id] = {
          ...device,
          isReconfiguring: true,
        };
      }
    }
  );
  data.home.brilliantSwitches.forEach((device: IBrilliantSwitchCard) => {
    switchDict[device.id] = { ...device, isReconfiguring: false };
  });
  data.home.installationDetails?.installedBrilliantSwitches.forEach(
    (device: IBrilliantSwitchCard) => {
      if (!(device.id in switchDict)) {
        switchDict[device.id] = { ...device, isReconfiguring: true };
      }
    }
  );
  data.home.brilliantPlugs.forEach((device: IBrilliantPlugCard) => {
    plugDict[device.id] = { ...device, isReconfiguring: false };
  });
  data.home.installationDetails?.installedBrilliantPlugs.forEach(
    (device: IBrilliantPlugCard) => {
      if (!(device.id in plugDict)) {
        plugDict[device.id] = { ...device, isReconfiguring: true };
      }
    }
  );
  return {
    installedBrilliantControls: Object.values(controlDict).sort(
      (control1, control2) => control1.name.localeCompare(control2.name)
    ),
    installedBrilliantPlugs: Object.values(plugDict).sort((plug1, plug2) =>
      plug1.name.localeCompare(plug2.name)
    ),
    installedBrilliantSwitches: Object.values(switchDict).sort(
      (switch1, switch2) => switch1.name.localeCompare(switch2.name)
    ),
  };
}

// Used during reconfiguration to convert from IInstalledIntegrationDevice
// to IUnitDetailsIntegrations
// Add ONLINE status for integration devices during reconfiguration to avoid showing offline warnings.
// Add UNKNOWN deviceType during reconfiguration. Since user cannot see Integration deviceType no need to show actual deviceType.
export function createIntegrationCards(
  data: IUnitDetailsData
): IUnitDetailsIntegrations[] {
  const integrationsDict: Record<string, IUnitDetailsIntegrations> = {};
  data.home.installationDetails.installedIntegrationDevices.forEach(
    (integration: IInstalledIntegrationDevice) => {
      const integrationDevice = {
        connectivityStatus: DeviceStatus.Online,
        deviceType: DeviceTypes.UNKNOWN,
        name: integration.name,
      };
      if (!(integration.integrationType in integrationsDict)) {
        integrationsDict[integration.integrationType] = {
          integrationDevices: [integrationDevice],
          name: integration.integrationType,
          numDevices: 1,
        };
      } else {
        integrationsDict[integration.integrationType].numDevices += 1;
        integrationsDict[integration.integrationType].integrationDevices.push(
          integrationDevice
        );
      }
    }
  );
  const integrations: IUnitDetailsIntegrations[] =
    Object.values(integrationsDict);
  if (data.home.numRemotelockDevices > 0) {
    integrations.push({
      integrationDevices: [
        {
          connectivityStatus: DeviceStatus.Online,
          deviceType: DeviceTypes.UNKNOWN,
          name: "RemoteLock",
        },
      ],
      name: "RemoteLock",
      numDevices: data.home.numRemotelockDevices,
    });
  }
  return integrations;
}

const UnitDetails: React.FC<UnitDetailsProps> = ({
  match,
  showViewConfiguration,
  organizationId,
  enableMakeChangesFlow = false,
}) => {
  const { propertyId } = match.params;
  const [showMakeChangesModal, setShowMakeChangesModal] = useState(false);
  const [showInviteTenantModal, setShowInviteTenantModal] = useState(false);
  const [showEditTenantModal, setShowEditTenantModal] = useState(false);
  const [showMoveOutModal, setShowMoveOutModal] = useState(false);
  const [showResetInstallationModal, setShowResetInstallationModal] =
    useState(false);
  const [showCancelMoveInModal, setShowCancelMoveInModal] = useState(false);
  const [currentUnitDetailsFilter, setUnitDetailsFilter] = useState(
    EUnitDetailsFilter.brilliants
  );
  const [showBackupWifiNetworkModal, setShowBackupWifiNetworkModal] =
    useState(false);
  const [removeTenantCurrentStep, setRemoveTenantCurrentStep] =
    useState(SHOW_TENANT_STEP);
  const [inviteTenantCurrentStep, setInviteTenantCurrentStep] = useState(
    NEW_INVITE_RESIDENT_STEP
  );
  const [showReconfigurationModal, setShowReconfigurationModal] =
    useState(false);
  const [showBannerReconfigurationModal, setShowBannerReconfigurationModal] =
    useState(false);
  const [isEnabled] = useFeature("make_changes_flow");
  const { loading, data, refetch } = useQuery<
    IUnitDetailsData,
    IPropertyIdVariable
  >(GET_HOME, {
    variables: { propertyId },
  });
  const {
    loading: organizationIntegrationsLoading,
    data: organizationIntegrations,
  } = useQuery<IOrganizationData, IOrganizationParams>(
    GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
    {
      variables: { organizationId },
    }
  );
  let unitName = "";
  let buildingName = "";
  useEffect(() => {
    smallHeaderTitle(unitName);
    smallHeaderDescription(buildingName);
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderDescription("");
      smallHeaderBackArrow(false);
    };
  }, [data]);
  if (loading || organizationIntegrationsLoading) {
    return <Spinner />;
  }
  if (!data || !organizationIntegrations) {
    throw Error("No data was returned");
  }
  const integrationType = checkIntegrations(
    organizationIntegrations.organization.integrations
  );
  const {
    entrataIntegration,
    realPageIntegration,
    remoteLockIntegration: hasRemoteLockIntegration,
  } = integrationType;
  const hasPMSIntegration = entrataIntegration || realPageIntegration;
  const integrationNameLink = renderIntegrationInfo({ integrationType });
  const totalTenants = data.home.numResidents + data.home.numInvitedResidents;
  const isOccupied = data.home.numResidents > 0;
  const isInReconfigState = isReconfigurationInProgress(
    data.home.reconfigurationState
  );
  let brilliantDevices;
  let integrations = data.home.integrations.map((integration) => ({
    ...integration,
  }));
  if (isInReconfigState) {
    brilliantDevices = createDeviceDict(data);
    integrations = createIntegrationCards(data);
  } else {
    const controls = data.home.brilliantControls.map((control) => ({
      ...control,
    }));
    const plugs = data.home.brilliantPlugs.map((plug) => ({
      ...plug,
    }));
    const switches = data.home.brilliantSwitches.map((switchDevice) => ({
      ...switchDevice,
    }));
    brilliantDevices = {
      installedBrilliantControls: controls.sort((control1, control2) =>
        control1.name.localeCompare(control2.name)
      ),
      installedBrilliantPlugs: plugs.sort((plug1, plug2) =>
        plug1.name.localeCompare(plug2.name)
      ),
      installedBrilliantSwitches: switches.sort((switch1, switch2) =>
        switch1.name.localeCompare(switch2.name)
      ),
    };
  }
  integrations.sort((integration1, integration2) =>
    integration1.name.localeCompare(integration2.name)
  );
  const numBrilliantDevices =
    brilliantDevices.installedBrilliantControls.length +
    brilliantDevices.installedBrilliantSwitches.length +
    brilliantDevices.installedBrilliantPlugs.length;
  const numIntegrations = integrations.length;
  const shouldShowChangeActivity =
    !isOccupied && (isEnabled || enableMakeChangesFlow);
  unitName = data.home.propertyName;
  buildingName = data.home.parentProperties[0].name;
  const crumbs = [
    {
      name: buildingName,
      path: `/buildings/${data.home.parentProperties[0].propertyId}`,
    },
    {
      name: unitName,
      path: history.location.pathname,
    },
  ];

  const reconfiguringControls = brilliantDevices.installedBrilliantControls
    .filter((device) => device.isReconfiguring)
    .map((device) => device.name);

  const filteredAlerts = data.home.alerts.filter((alert) => {
    switch (alert.alarmType) {
      case AlertTypes.ExtremeTemperature:
      case AlertTypes.LeakDetected:
      case AlertTypes.ReconfigurationTakingTooLong:
      case AlertTypes.ResidentInviteFailed:
      case AlertTypes.LowBattery:
        return true;
      default:
        return false;
    }
  });
  const alertsSortedByTitle = _.sortBy(filteredAlerts, (alert) => {
    return renderAlertTitle({
      alarmType: alert.alarmType,
      ambientTemperatureF: alert.ambientTemperatureF,
    });
  });
  return (
    <>
      {isInReconfigState &&
        !alertsSortedByTitle.some((alert) => {
          return alert.alarmType === AlertTypes.ReconfigurationTakingTooLong;
        }) && (
          <ReconfigurationBanner
            setShowBannerReconfigurationModal={
              setShowBannerReconfigurationModal
            }
          />
        )}
      {alertsSortedByTitle.map((alert: IAlert) => {
        return (
          <AlertBanner
            alarmType={alert.alarmType}
            buildingName={alert.building.propertyName}
            deviceName={alert.deviceName}
            key={alert.id}
            unitName={alert.home.propertyName}
            ambientTemperatureF={alert.ambientTemperatureF}
            reconfiguringDeviceNames={reconfiguringControls}
            deviceType={alert.deviceType}
          />
        );
      })}
      {showReconfigurationModal && (
        <ReconfigurationModal
          headerText="Reconfiguration Not Finished"
          closeReconfigurationModal={() => setShowReconfigurationModal(false)}
        >
          <div className="modal-body-reconfiguration-text">
            You must wait for this unit to finish reconfiguring before you can
            perform this action.
          </div>
          <br />
          <div className="modal-body-reconfiguration-text">
            Please wait 5-10 minutes and try again.
          </div>
        </ReconfigurationModal>
      )}
      {showBannerReconfigurationModal && (
        <ReconfigurationModal
          headerText="Reconfiguration In Progress"
          closeReconfigurationModal={() =>
            setShowBannerReconfigurationModal(false)
          }
        >
          <div className="modal-body-reconfiguration-text">
            This unit&apos;s Brilliant home is currently being reconfigured with
            default settings to complete a resident transition.
          </div>
          <br />
          <div className="modal-body-reconfiguration-text">
            Unit reconfiguration should take about 5 minutes, but in some cases
            may take longer.
          </div>
        </ReconfigurationModal>
      )}

      <div className="center-align-as-column">
        <div className="padding-bottom-large center-align-as-column unit-details-main-container-small">
          {showMakeChangesModal && (
            <MakeChangesModal
              closeMakeChangesModal={() => setShowMakeChangesModal(false)}
              propertyName={data.home.parentProperties[0].name}
              unitName={data.home.propertyName}
              homeId={data.home.homeId}
              refetch={refetch}
            />
          )}
          {showInviteTenantModal && (
            <InviteTenantModalWizard
              closeInviteTenantModal={() => setShowInviteTenantModal(false)}
              currentStep={inviteTenantCurrentStep}
              setCurrentStep={setInviteTenantCurrentStep}
              propertyId={propertyId}
              propertyName={data.home.parentProperties[0].name}
              unitName={data.home.propertyName}
            />
          )}
          {showEditTenantModal && (
            <EditTenantModalWizard
              closeRemoveTenantModal={() => {
                setShowEditTenantModal(false);
              }}
              hasRemoteLockIntegration={hasRemoteLockIntegration}
              currentStep={removeTenantCurrentStep}
              setCurrentStep={setRemoveTenantCurrentStep}
              propertyName={data.home.parentProperties[0].name}
              unitName={data.home.propertyName}
              propertyId={propertyId}
              homeId={data.home.homeId}
              setUnitDetailsFilter={setUnitDetailsFilter}
              hasIntegration={hasPMSIntegration}
              integrationNameLink={integrationNameLink}
            />
          )}
          {showMoveOutModal && (
            <MoveOutModalWizard
              closeMoveOutModal={() => {
                setShowMoveOutModal(false);
              }}
              propertyName={buildingName}
              unitName={unitName}
              propertyId={propertyId}
            />
          )}
          {showCancelMoveInModal && (
            <CancelMoveInModal
              closeCancelMoveInModal={() => {
                setShowCancelMoveInModal(false);
              }}
              propertyName={buildingName}
              unitName={unitName}
              propertyId={propertyId}
              setUnitDetailsFilter={setUnitDetailsFilter}
            />
          )}
          {showBackupWifiNetworkModal && (
            <div className="managers-container center-align">
              <BackupWifiNetworkModal
                data={{
                  networkName: data.home.wifiCredentials.networkName,
                  networkType: data.home.wifiCredentials.networkType,
                  password: data.home.wifiCredentials.password,
                  propertyId: data.home.id,
                  securityType: data.home.wifiCredentials.securityType,
                }}
                closeModalFn={() => setShowBackupWifiNetworkModal(false)}
              />
            </div>
          )}
          {showResetInstallationModal && (
            <ResetInstallationModalWizard
              closeResetInstallationModalWizardModal={() => {
                setShowResetInstallationModal(false);
              }}
              propertyName={buildingName}
              unitName={unitName}
              propertyId={propertyId}
            />
          )}
          {showViewConfiguration && (
            <UnitDetailsView
              homeId={data.home.homeId}
              buildingName={data.home.parentProperties[0].name}
              unitName={data.home.propertyName}
            />
          )}
          <div className="unit-details-main-container">
            <Breadcrumbs
              crumbs={crumbs}
              rootCrumb={{ name: "Dashboard", path: "/" }}
            />

            <UnitDetailsMainInfoForLargeScreen
              home={data.home}
              totalTenants={totalTenants}
              numBrilliantDevices={numBrilliantDevices}
              numIntegrations={numIntegrations}
              setShowInviteTenantModal={setShowInviteTenantModal}
              setInviteTenantCurrentStep={setInviteTenantCurrentStep}
              setShowMoveOutModal={setShowMoveOutModal}
              setShowCancelMoveInModal={setShowCancelMoveInModal}
              setShowReconfigurationModal={setShowReconfigurationModal}
              hasIntegration={hasPMSIntegration}
              isInReconfigState={isInReconfigState}
            />
            <UnitDetailsMainInfoForSmallScreen
              isHubless={data.home.isHubless}
              currentUnitDetailsFilter={currentUnitDetailsFilter}
              totalTenants={totalTenants}
              numBrilliantDevices={numBrilliantDevices}
              numIntegrations={numIntegrations}
              setUnitDetailsFilter={setUnitDetailsFilter}
            />
            <LargeBody
              home={data.home}
              brilliantDevices={brilliantDevices}
              shouldShowChangeActivity={shouldShowChangeActivity}
              propertyId={propertyId}
              openEditResidentModal={() => setShowEditTenantModal(true)}
              setShowBackupWifiNetworkModal={setShowBackupWifiNetworkModal}
              setShowMoveOutModal={setShowMoveOutModal}
              setShowResetInstallationModal={setShowResetInstallationModal}
              setShowInviteTenantModal={setShowInviteTenantModal}
              setInviteTenantCurrentStep={setInviteTenantCurrentStep}
              setShowCancelMoveInModal={setShowCancelMoveInModal}
              setShowReconfigurationModal={setShowReconfigurationModal}
              hasIntegration={hasPMSIntegration}
              integrationNameLink={integrationNameLink}
              isInReconfigState={isInReconfigState}
              integrations={integrations}
            />
            <SmallBody
              home={data.home}
              brilliantDevices={brilliantDevices}
              currentUnitDetailsFilter={currentUnitDetailsFilter}
              propertyId={propertyId}
              isInReconfigState={isInReconfigState}
              setShowBackupWifiNetworkModal={setShowBackupWifiNetworkModal}
              setShowResetInstallationModal={setShowResetInstallationModal}
              setShowInviteTenantModal={setShowInviteTenantModal}
              openEditTenantModalWizard={() => setShowEditTenantModal(true)}
              hasIntegration={hasPMSIntegration}
              integrationNameLink={integrationNameLink}
              integrations={integrations}
              setInviteTenantCurrentStep={setInviteTenantCurrentStep}
              setShowMoveOutModal={setShowMoveOutModal}
              setShowCancelMoveInModal={setShowCancelMoveInModal}
              setShowReconfigurationModal={setShowReconfigurationModal}
            />
          </div>
        </div>
        {shouldShowChangeActivity && (
          <button
            onClick={() => {
              setShowMakeChangesModal(true);
            }}
            type="button"
            className="primary-btn unit-configurations-btn"
          >
            Make Changes
          </button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
    showViewConfiguration: state.selectedScheduledConfiguration.showModal,
  };
};

export default connect(mapStateToProps)(UnitDetails);

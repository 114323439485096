import { useState } from "react";
import { useFeature } from "@optimizely/react-sdk";

import { quantityDisplay } from "../../utils/utils";
import BaseModal from "../modals/BaseModal";
import {
  DeviceStatus,
  DeviceTypes,
  IIntegrationDevices,
  IIntegrationsCard,
} from "../../interfaces/interfaces";
import { ReactComponent as UnknownDevice } from "../../img/3PDevices/UnknownDevice.svg";
import { ReactComponent as SmartLock } from "../../img/SmartLock.svg";
import { ReactComponent as Thermostat } from "../../img/3PDevices/Thermostat.svg";
import { ReactComponent as ButterflyMX } from "../../img/3PDevices/BMX.svg";
import { ReactComponent as LeakDetector } from "../../img/3PDevices/LeakDetector.svg";
import { ReactComponent as GenericOutlet } from "../../img/3PDevices/GenericOutlet.svg";
import { ReactComponent as GenericLight } from "../../img/3PDevices/GenericLight.svg";
import { ReactComponent as WaterValve } from "../../img/3PDevices/WaterValve.svg";
import { ReactComponent as Music } from "../../img/3PDevices/Music.svg";
import { ReactComponent as DoorBell } from "../../img/3PDevices/DoorBell.svg";

const integrationDeviceTypeToImage = ({
  deviceType,
}: {
  deviceType: string;
}) => {
  switch (deviceType) {
    case DeviceTypes.LOCK:
      return <SmartLock />;
    case DeviceTypes.OFFLINE_LOCK:
      return <SmartLock />;
    case DeviceTypes.THERMOSTAT:
      return <Thermostat />;
    case DeviceTypes.CLIMATE_SENSOR:
      return <LeakDetector />;
    case DeviceTypes.BUILDING_ENTRY_PANEL:
      return <ButterflyMX />;
    case DeviceTypes.OUTLET:
      return <GenericOutlet />;
    case DeviceTypes.LIGHT:
      return <GenericLight />;
    case DeviceTypes.WATER_SHUTOFF_VALVE:
      return <WaterValve />;
    case DeviceTypes.MUSIC:
      return <Music />;
    case DeviceTypes.DOORBELL:
      return <DoorBell />;
    default:
      return <UnknownDevice />;
  }
};

export interface IntegrationCardProps {
  data: IIntegrationsCard;
  isSmartCardCredentialsEnabledStories?: boolean;
  isReconfiguring: boolean;
}

const renderOfflineDevicesAmount = (
  offlineDevices: IIntegrationDevices[],
  totalNumOfIntegrationDevices: number
) => {
  return (
    <div className="brilliant-card-installation-error">
      {`${
        offlineDevices.length
      } of ${totalNumOfIntegrationDevices}  ${quantityDisplay(
        offlineDevices.length,
        "Devices"
      )} Offline`}
    </div>
  );
};

const displayDeviceType = (integrationDevice: IIntegrationDevices) => {
  if (integrationDevice.deviceType === DeviceTypes.LOCK) {
    return (
      <div className="unit-description-small">
        {integrationDevice.isCommonArea ? "COMMON AREA" : "UNIT"}
      </div>
    );
  }
  return (
    <div className="unit-description-small">
      {integrationDevice.deviceType === DeviceTypes.CLIMATE_SENSOR
        ? "LEAK DETECTOR"
        : integrationDevice.deviceType.replace(/_/g, " ")}
    </div>
  );
};

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  data,
  isSmartCardCredentialsEnabledStories = false,
  isReconfiguring,
}) => {
  const [showDevicesDetail, setShowDevicesDetail] = useState(false);
  const [isOfflineDevices] = useFeature("offline_devices");
  const offlineDevices = data.integrationDevices.filter((integrationDevice) => {
    return integrationDevice.connectivityStatus !== DeviceStatus.Online;
  });
  const totalNumOfIntegrationDevices = data.integrationDevices.length;
  return (
    <>
      {showDevicesDetail &&
        (isOfflineDevices || isSmartCardCredentialsEnabledStories) && (
          <BaseModal
            headerText={`${data.name} Devices`}
            closeModalFn={() => setShowDevicesDetail(false)}
            actionButtonFn={() => undefined}
          >
            <div className="brilliant-card-details-container">
              {data.integrationDevices.map((integrationDevice) => {
                return (
                  <div className="brilliant-card-details-description-container">
                    {integrationDeviceTypeToImage({
                      deviceType: integrationDevice.deviceType,
                    })}
                    <div>
                      <div className="unit-title hidden-name hidden-name-1">
                        {integrationDevice.name}
                      </div>
                      {displayDeviceType(integrationDevice)}
                      {integrationDevice.connectivityStatus !==
                        DeviceStatus.Online && (
                        <div className="brilliant-card-installation-error">
                          Offline
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </BaseModal>
        )}
      <button
        type="button"
        className="brilliant-card-container"
        onClick={() => {
          if (!isReconfiguring) setShowDevicesDetail(true);
        }}
        disabled={isReconfiguring}
      >
        <div className="brilliant-card-description">
          <div className="unit-title hidden-name">{data.name}</div>
          <div className="brilliant-card-description-id unit-description-small flex-display uppercase">
            {`${data.numDevices} ${quantityDisplay(data.numDevices, "DEVICE")}`}
          </div>
          {offlineDevices.length > 0 &&
            (isOfflineDevices || isSmartCardCredentialsEnabledStories) &&
            renderOfflineDevicesAmount(
              offlineDevices,
              totalNumOfIntegrationDevices
            )}
          {isReconfiguring && (
            <div className="brilliant-card-description-reconfiguring unit-device-id">
              RECONFIGURING...
            </div>
          )}
        </div>
      </button>
    </>
  );
};
export default IntegrationCard;
